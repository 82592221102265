let cases = [
    ["البليدة",575,36.4889434,2.7685398], // blida
    ["الجزائر العاصمة",368,36.7631,3.0506,], // alger
    ["وهران",134,35.69,-0.64], // oran
    ["سطيف",87,36.19,5.41], // setif
    ["بجاية",82,36.75,5.0833], // béjaia
    ["تيبازة",69,36.592,2.449], // tipaza
    ["عين الدفلى",63,36.26405,1.9679], // ain defla
    ["تيزي وزو",53,36.7169,4.0497], // tizi ouzou
    ["الشلف",41,36.1653,1.3345], // chlef 
    ["برج بوعريريج",41,36.0676,4.7453], // bordj bouarreridj
    ["قسنطينة",40,36.365,6.61472], // constantine
    ["تلمسان",38,34.878330,-1.315], // tlemcen 
    ["مستغانم",35,35.931150,0.089180], // mostaghanem
    ["الجلفة",29,34.67279,3.263], // djelfa 
    ["المدية",28,36.2653,2.767], //medea
    ["بومرداس",28,36.77,3.48], // boumerdes
    ["تيسمسيلت",26,35.60,1.81], // tissemsilt
    ["جيجل",24,36.82055,5.76671], // jijel
    ["الوادي",23,33.368309, 6.867369], // eloued 
    ["سكيكدة",22,36.8799,6.9075], // skikda 
    ["سيدي بلعباس",21,35.189940,-0.630850], // sidi belabbes
    ["باتنة",21,35.55597,6.17414], // batna
    ["معسكر",20,35.3941,0.138], // mascara
    ["أم البواقي",18,35.87541,7.11353], // oum elbouaghi
    ["غرداية",17,32.49094,3.67347], // gharadaia
    ["البويرة",16,36.38,3.9014], // bouira
    ["عين تموشنت",15,35.297490,-1.140370], // ain temouchent
    ["عنابة",14,36.9,7.7667], // annaba 
    ["ورقلة",14,31.9575,5.3278], // ouargla
    ["بسكرة",11,34.85038,5.72805], // biskra
    ["خنشلة",11,35.43583,7.14333], // khenchela
    ["الأغواط",11,33.80,2.86514], // laghouat
    ["بشار",10,31.61667,-2.21667], //bechar
    ["المسيلة",10,35.70583,4.54194], // m'sila
    ["غليزان",10,35.73734,0.55599], // relizane
    ["تبسة",7,35.40417,8.12417], // tebessa
    ["قالمة",6,36.4651,7.4306], // guelma
    ["سوق أهراس",6,36.2848,7.9515], // souk ahras
    ["تيارت",6,35.37103,1.3167], // tiaret
    ["أدرار",6,27.9,-0.2833], // adrar 
    ["الطارف",4,36.767,8.317], // el taref
    ["ميلة",4,36.45028,6.26444], // mila
    ["إليزي",2,26.50,8.473], // illizi
    ["البيض",1,33.68318,1.01927], // elbayadh
    ["النعامة",1,33.267398,-0.3134536], // naama
    ["سعيدة",1,34.83033,0.15171], // saida
    ["تمنراست",1,22.785,5.52278], // tamanrasset
    // ["تندوف",1,22.785,5.52278], // tindouf
];

module.exports = cases;